import Container from "../../../../content/blog/building-a-range-slider-component-in-react/components/container.js";
import RangeSlider from "../../../../content/blog/building-a-range-slider-component-in-react/components/range-slider.js";
import SliderCodepenEmbed from "../../../../content/blog/building-a-range-slider-component-in-react/components/codepen.js";
import Model from "../../../../content/blog/building-a-range-slider-component-in-react/components/model.js";
import * as React from 'react';
export default {
  Container,
  RangeSlider,
  SliderCodepenEmbed,
  Model,
  React
};